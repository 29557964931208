
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































































































.blocks {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  // @include mq(xs) {
  //   flex-direction: row;
  // }

  // @include mq(s) {
  //   flex-direction: column;
  // }

  @include mq(xl) {
    flex-direction: row;
  }
}

.block {
  &:not(:last-child) {
    @include mq(s) {
      margin-bottom: 3rem;
    }

    @include mq(xl) {
      margin-right: col(1, 9);
    }
  }

  // @include mq(xs) {
  //   width: col(6, 14);
  //   margin-right: col(1, 14);
  // }

  @include mq(s) {
    width: 100%;
    margin-right: 0;
  }

  @include mq(l) {
    width: col(7, 9);
  }

  @include mq(xl) {
    width: col(4, 9);
  }
}

.block__header {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 10px, xxl: 20px));

  display: flex;
  align-items: center;
  border-bottom: 2px solid $elantis-blue;
}

.block__header__icon {
  margin-right: 1.5rem;
}

.block__header__title {
  @extend %fw-normal;
}

.loan-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.no-loan__picture {
  margin: 0 auto 2rem;
}
