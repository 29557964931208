
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































.loan-recap,
[class*='loan-recap--'] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1;

  hr {
    height: 1px;
    margin: 0;
    background: $elantis-grey-light;
    border: 0;
  }
}

.loan-recap__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;

  small {
    margin-left: 1.5rem;
  }
}

.loan-recap__view-icon {
  fill: $tundora;

  .loan-recap:hover & {
    fill: $elantis-blue;
  }
}

.loan-recap__header__label {
  font-weight: 400;
}

.loan-recap__header__label,
.loan-recap__footer__label {
  margin-right: 1rem;
}

.loan-recap__footer__amount {
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    margin-top: -5px;
  }
}

.loan-recap__list {
  margin: 0;
  padding: 0;
}

.loan-recap__list__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  list-style-type: none;
}

.loan-recap__list__block {
  border-bottom: 2px solid $elantis-blue;
}

.loan-recap__block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-decoration: none;
}

.loan-link {
  padding: 1rem 1rem 0.7rem;
  line-height: 1;
  text-decoration: none;
  transition: box-shadow 0.25s;

  &:hover {
    border-radius: 6px;
    box-shadow: 0 10px 40px rgba(11, 93, 183, 0.2);
  }
}

.loan-recap__amount {
  @extend %fw-medium;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3rem;
  line-height: 1;
}

.loan-recap__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.2;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.loan-recap__footer__date {
  // prettier-ignore
  @include fluid(font-size, (xxs: 12px, xxl: 14px));
}
